import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, TextField, Grid, Box, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Header from '../components/common/Header';
import ContentWrapper from '../components/common/ContentWrapper';
import {
  createTask,
  changeField,
  initialize,
  setOriginalTask,
  updateTask,
} from '../modules/create';

const CreatePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const editId = state?.editId;
  const isEdit = state?.isEdit ?? false;
  const [error, setError] = useState(null);
  const [tagInput, setTagInput] = useState('');
  const {
    title,
    description,
    tags,
    serverRepoAddr,
    task: originalTask,
  } = useSelector(({ create, tasks }) => ({
    title: create.title,
    description: create.description,
    tags: create.tags,
    serverRepoAddr: create.serverRepoAddr,
    task: tasks.task, // this task is fetched by getTask action
  }));



return (
  <div>
    <Header />
    <ContentWrapper>
    
    </ContentWrapper>
  </div>
);};

export default CreatePage;
