import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MainPage from './pages/MainPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import TaskPage from './pages/TaskPage';
import CreatePage from './pages/CreatePage';
import TaskDetailPage from './pages/TaskDetailPage';
import ProtectedRoute from './components/route/ProtectedRoute';
import MonitoringPage from './pages/MonitoringPage';
import BlogPage from './pages/BlogPage'

const App = () => {
  return (
    <Routes>
      <Route path="/fedops" element={<MainPage />} />
      <Route path="/fedops/login" element={<LoginPage />} />
      <Route path="/fedops/register" element={<RegisterPage />} />
      <Route path="/fedops/task" element={<ProtectedRoute component={TaskPage} />} />
      <Route
        path="/fedops/task/create"
        element={<ProtectedRoute component={CreatePage} />}
      />
      <Route
        path="/fedops/task/:title/*"
        element={<ProtectedRoute component={TaskDetailPage} />}
      />
      <Route path="/fedops/blog" element={<BlogPage/>} />
    </Routes>
  );
};

export default App;
